




















import { Mixins, Component, Watch } from "vue-property-decorator";
import AppMixin from "@/mixins/App";
import Article from "@/models/Article";
import { Route } from "vue-router";
import SectionPage from "@/components/common/SectionPage.vue";
import SliderCarousel from "@/modules/portfolio/components/SliderCarousel.vue";

@Component({
  components: {
    SectionPage,
    SliderCarousel
  }
})
export default class PortfolioItem extends Mixins(AppMixin) {
  obArticle: Article = new Article();

  mounted() {
    this.load();
  }

  @Watch("$route")
  onChangeRoute(route: Route, oldRoute: Route) {
    if (route.params.slug !== oldRoute.params.slug) {
      this.load();
    }
  }

  async load() {
    if (!this.$route.params.slug) {
      return;
    }

    const obArticle = new Article();
    obArticle.set("slug", this.$route.params.slug);
    const response = await obArticle.fetch();
    if (response) {
      const obData = response.getData();
      this.obArticle.assign(obData.data || obData);
    }
  }

  get hasImages() {
    return this.obArticle
      ? !!this.obArticle.images.length || !!this.obArticle.preview_image
      : false;
  }

  get images() {
    if (!this.hasImages) {
      return [];
    }

    if (this.obArticle.images.length) {
      return this.$_.map(this.obArticle.images, image => {
        return {
          src: image.thumb,
          url: image.path,
          alt: image.title || this.obArticle.title
        };
      });
    }

    return {
      src: this.obArticle.preview_image,
      alt: this.obArticle.title
    };
  }
}
